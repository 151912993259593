import React from 'react';
import ReactGA from 'react-ga';
import './DropdownCircuits.css';
import { ReactComponent as DownSVG } from '../icon/expand_more.svg';
import { ReactComponent as MenuSVG } from '../icon/menu.svg';
import { ReactComponent as CloseSVG } from '../icon/close.svg';
import { Circuit } from '../types/landingPage';
import { MappingData } from '../types/mapping';

interface DropdownState {
  dropdown: boolean;
}

interface DropdownProps {
  circuitsList: Circuit[];
  changeCircuit: CallableFunction;
  night?: boolean;
  currentMapping: string;
}

export default class DropdownCircuits extends React.Component<DropdownProps> {
  state: DropdownState = {
    dropdown: false,
  };
  render = () => {
    const drop = this.state.dropdown ? 'drop' : '';
    const height = this.props.circuitsList.length * 40 + 'px';
    const dropStyle = this.state.dropdown ? { height: height } : { height: 0 };
    const circuits = this.props.circuitsList.map((c, index) => {
      let mappingData: MappingData = require(`../JSON/${c.mapping}`);
      return (
        <p
          key={`circuit-${index}`}
          onClick={() => {
            if (this.props.currentMapping !== c.mapping) {
              ReactGA.event({
                category: 'Circuits Dropdown',
                action: `Select Circuit '${
                  mappingData.sectors[mappingData.start.sectorIndex].name
                }'`,
              });
              const space = this.props.night
                ? mappingData.start.spaceIdN
                : mappingData.start.spaceId;
              this.props.changeCircuit(space, c.mapping);
            }
            this.setState({ dropdown: false });
          }}
        >
          {c.name}
        </p>
      );
    });
    let current;
    this.props.circuitsList.forEach((c) => {
      if (c.mapping === this.props.currentMapping) {
        current = c.name;
      }
    });

    const mobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent,
    );

    if (mobile) {
      if (this.state.dropdown) {
        return (
          <div className="circuits-menu">
            <div className="menu">
              <div className="choose-label">
                <svg>
                  <line x1="0" y1="0" x2="100" y2="0" />
                </svg>
                <p>escolha um circuito</p>
              </div>
              <div className="m-circuits">{circuits}</div>
            </div>
            <div className="footer-bar">
              <CloseSVG
                onClick={() => {
                  ReactGA.event({
                    category: 'Circuits Dropdown',
                    action: `Open`,
                  });
                  this.setState({ dropdown: false });
                }}
              />
              <p>Menu</p>
            </div>
          </div>
        );
      }

      return (
        <MenuSVG
          onClick={() => {
            ReactGA.event({
              category: 'Circuits Dropdown',
              action: `Open`,
            });
            this.setState({ dropdown: true });
          }}
        />
      );
    }

    return (
      <div className="dropdown">
        <div
          className={'dropdown-circuit ' + drop}
          onClick={() => {
            ReactGA.event({
              category: 'Circuits Dropdown',
              action: `Open`,
            });
            this.setState({ dropdown: true });
          }}
        >
          Circuito {current} <DownSVG />
        </div>
        <div className={'circuits ' + drop} style={dropStyle}>
          {circuits}
        </div>
        {this.state.dropdown ? (
          <div
            className="dropdown-background"
            onClick={() => {
              ReactGA.event({
                category: 'Circuits Dropdown',
                action: `Close`,
              });
              this.setState({ dropdown: false });
            }}
          ></div>
        ) : null}
      </div>
    );
  };
}
